import Client1 from "../../assets/img/client/client1.jpg";
import Client2 from "../../assets/img/client/client2.jpg";
import Client3 from "../../assets/img/client/client3.jpg";
import Client4 from "../../assets/img/client/client4.jpg";
import WrapperImage from "../../assets/img/client/clientbg.jpg";
import StarRatings from "react-star-ratings";

const Client = () => {
  const expertise = [
    {
      name: "Amelia Sithole",
      location: "South Africa",
      description:
        "Recently we deal with The One Infinity International Export Import company for the garments, and the quality of garments are excellent. We would like to work more with you.",
      image: Client1,
    },
    {
      name: "Shaikh Riyazuddin",
      location: "Dubai",
      description:
        "We deal with The One Infinity International from the last 2 years. The quality of spices and namkeen are amazing. I highly recommended to this company to all.",
      image: Client2,
    },
    {
      name: "Ethan Clarke",
      location: "Australia",
      description:
        "We deal with The One Infinity International Export Import for the grocery products, Specially rice and frozen products. The way they deliver our order with proper packaging are excellent.",
      image: Client3,
    },
    {
      name: "Ankita Shah",
      location: "United Kingdom",
      description:
        "Such a beautiful collection of handicraft products and wooden temples provided by The One Infinity International and the way Mr. Pareshbhai Solanki respond us with the proper product details are amazing.  I strongly recommended to all.",
      image: Client4,
    },
  ];

  return (
    <section
      id="services"
      className="services section"
      style={{
        backgroundImage: `url(${WrapperImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      }}
    >
      <div className="container section-title" data-aos="fade-up">
        <h2 style={{ color: "#fff" }}>Clients</h2>
        <p style={{ color: "#fff" }}>Love from clients</p>
      </div>

      <div className="container">
        <div className="row">
          {expertise?.map((item) => (
            <div
              key={item.name}
              className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
              style={{
                margin: "0.8rem 0",
              }}
            >
              <div
                className="client-item position-relative"
                style={{
                  display: "flex",
                  height: "100%",
                  border: "5px solid #ffc451",
                }}
              >
                <img
                  src={item.image}
                  alt={item.image}
                  style={{
                    width: "8rem",
                    height: "8rem",
                    margin: "auto",
                    marginLeft: "2rem",
                  }}
                />
                <div style={{ textAlign: "left", padding: "1rem" }}>
                  <h5
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    {item?.name}
                  </h5>
                  <h6
                    style={{
                      fontWeight: "bold",
                      color: "#718093",
                    }}
                  >
                    {item?.location}
                  </h6>
                  <StarRatings
                    rating={5}
                    starRatedColor="#ffc451"
                    numberOfStars={5}
                    name="rating"
                    starDimension="20px"
                    starSpacing="1px"
                  />
                  <p style={{ marginTop: "1rem" }}> {item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Client;
