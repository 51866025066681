import Gallery1 from "../../assets/img/gallery/gallery1.jpg";
import Gallery2 from "../../assets/img/gallery/gallery2.jpg";
import Gallery3 from "../../assets/img/gallery/gallery3.jpg";
import Gallery4 from "../../assets/img/gallery/gallery4.jpg";
import Gallery5 from "../../assets/img/gallery/gallery5.jpg";
import Gallery6 from "../../assets/img/gallery/gallery6.jpg";
import Gallery7 from "../../assets/img/gallery/gallery7.jpg";
import Gallery8 from "../../assets/img/gallery/gallery8.jpg";

const gallerys = [
  Gallery1,
  Gallery2,
  Gallery3,
  Gallery4,
  Gallery5,
  Gallery6,
  Gallery7,
  Gallery8,
];

const Gallery = () => {
  return (
    <div className="row section-title" style={{ margin: "10rem 3rem 5rem" }}>
      <p style={{ marginBottom: "1rem" }}> Bussiness Gallery </p>
      {gallerys?.map((image) => (
        <div
          key={image}
          className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
          style={{ marginBottom: "2rem" }}
        >
          <div className="card" style={{border:"5px solid #ffc451"}}>
            <div className="card-body">
              <img
                src={image}
                alt={image}
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Gallery;
