import { useLocation, Link } from "react-router-dom";
import Logo from "../../assets/img/logo.png";

const DesktopHeader = () => {
  const location = useLocation();
  const path = location?.pathname;
  const active = { color: "#ffc451" };

  return (
    <header
      id="header"
      className="header d-flex align-items-center fixed-top bg-dark"
    >
      <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
        <img
          src={Logo}
          alt={Logo}
          style={{ width: "10rem" }}
          className="logo d-flex align-items-center me-auto"
        />
        <nav id="navmenu" className="navmenu">
          <ul>
            <li>
              <Link
                to="/home"
                style={path === "/home" || path === "/" ? active : {}}
              >
                {" "}
                Home{" "}
              </Link>
            </li>
            <li className="dropdown">
              <a href="javascript:void(0)">
                <span>About</span>{" "}
                <i className="bi bi-chevron-down toggle-dropdown" />
              </a>
              <ul>
                <Link to="/company"> Company</Link>
                <Link to="/certification"> Certification</Link>
                <Link to="/key-person"> Key Person</Link>
              </ul>
            </li>

            <li>
              <Link
                to="/sourcing-agent"
                style={path === "/sourcing-agent" ? active : {}}
              >
                {" "}
                Sourcing Agent
              </Link>
            </li>

            <li class="dropdown">
              <a href="javascript:void(0)">
                <span>Products</span>{" "}
                <i class="bi bi-chevron-down toggle-dropdown"></i>
              </a>
              <ul>
                <li class="dropdown">
                  <a href="javascript:void(0)">
                    <span>Ceramic & Stones</span>{" "}
                    <i class="bi bi-chevron-down toggle-dropdown"></i>
                  </a>
                  <ul>
                    <li>
                      <Link to="/products/ceramic-&-stones/ceramic-&-stones">
                        {" "}
                        Ceramic Products
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/products/ceramic-&-stones/stone-products">
                        {" "}
                        Stone Products
                      </Link>{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/products/copper-products"> Copper Products</Link>
                </li>
                <li>
                  <Link to="/products/furniture"> Furniture</Link>
                </li>
                <li>
                  <Link to="/products/garments"> Garments</Link>
                </li>

                <li class="dropdown">
                  <a href="javascript:void(0)">
                    <span>Handicraft</span>{" "}
                    <i class="bi bi-chevron-down toggle-dropdown"></i>
                  </a>
                  <ul>
                    <li>
                      <Link to="/products/handicraft/bamboo-item">
                        {" "}
                        Bamboo Item
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/products/handicraft/decorative-products">
                        {" "}
                        Decorative Products
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/products/handicraft/flower-pot-products">
                        {" "}
                        Flower Pot Products
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/products/handicraft/wooden-handicraft-products">
                        {" "}
                        Wooden Handicraft Products
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/products/handicraft/wooden-temples">
                        {" "}
                        Wooden Temples
                      </Link>{" "}
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/products/imitation-jewellery">
                    {" "}
                    Imitation Jewellery
                  </Link>
                </li>
                <li>
                  <Link to="/products/insence-sticks"> Insence Sticks</Link>
                </li>
                <li>
                  <Link to="/products/leather-products"> Leather Products</Link>
                </li>
                <li>
                  <Link to="/products/packaging-products">
                    {" "}
                    Packaging Products
                  </Link>{" "}
                </li>
              </ul>
            </li>

            {/* <li>
              <Link to="/gallery" style={path === "/gallery" ? active : {}}>
                {" "}
                Gallery
              </Link>
            </li> */}

            <li>
              <Link to="/contact" style={path === "/contact" ? active : {}}>
                {" "}
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default DesktopHeader;
