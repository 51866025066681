import HowWeWork from "./howWeWork";
import Benefit from "./benefit";

const SourcingAgent = () => {
  return (
    <>
      <HowWeWork />
      <Benefit />
    </>
  );
};

export default SourcingAgent;
