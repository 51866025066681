import { Carousel } from "react-responsive-carousel";
import One from "../../assets/img/slider/1.jpg";
import Two from "../../assets/img/slider/2.jpg";
import Three from "../../assets/img/slider/3.jpg";
import Four from "../../assets/img/slider/4.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Slider = () => {
  const images = [One, Two, Three, Four];

  return (
    <div style={{ marginTop: "5rem", position: "relative" }}>
      <Carousel
        autoPlay={true}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
      >
        {images?.map((image) => (
          <div key={image}>
            <img src={image} alt={image} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Slider;
