/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.png";
import FooterLogo from "../../assets/img/logofooter.png";

const MobileHeader = () => {
  return (
    <nav className="navbar fixed-top" style={{ backgroundColor: "black" }}>
      <div className="container-fluid d-flex">
        <img
          src={Logo}
          alt={Logo}
          style={{ width: "10rem" }}
          className="logo d-flex "
        />
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
          style={{ border: "2px solid #ffc451" }}
        >
          <span
            className="navbar-toggler-icon"
            style={{ color: "#ffc451", filter: "brightness(100)" }}
          />
        </button>
        <div
          className="offcanvas offcanvas-end"
          tabIndex={-1}
          style={{backgroundColor:"#34495e"}}
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <img
              src={FooterLogo}
              alt={FooterLogo}
              style={{ width: "15rem", height: "auto" }}
              className="logo d-flex align-items-center me-auto"
            />
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </div>
          <div className="offcanvas-body" >
            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3 p-3">
              <li
                className="nav-item p-2"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                aria-label="Toggle navigation"
              >
                <Link to="/home"> Home </Link>
              </li>
              <li className="nav-item dropdown ps-2">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ color: "#ffc451" }}
                >
                  About
                </a>
                <ul className="dropdown-menu" style={{ border: "none", backgroundColor:"#34495e" }}>
                  <li
                    className="ps-3"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasNavbar"
                    aria-controls="offcanvasNavbar"
                    aria-label="Toggle navigation"
                  >
                    <Link to="/company"> Company</Link>
                  </li>
                  <li
                    className="ps-3 pt-2"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasNavbar"
                    aria-controls="offcanvasNavbar"
                    aria-label="Toggle navigation"
                  >
                    <Link to="/certification"> Certification</Link>
                  </li>

                  <li
                    className="ps-3 pt-2"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasNavbar"
                    aria-controls="offcanvasNavbar"
                    aria-label="Toggle navigation"
                  >
                    <Link to="/key-person"> Key Person</Link>
                  </li>
                </ul>
              </li>
              <li
                className="nav-item p-2"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                aria-label="Toggle navigation"
              >
                <Link to="/sourcing-agent"> Sourcing Agent</Link>
              </li>

              <li
                className="nav-item p-2"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                aria-label="Toggle navigation"
              >
                <Link to="/gallery"> Gallery</Link>
              </li>

              <li
                className="nav-item p-2"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                aria-label="Toggle navigation"
              >
                <Link to="/contact"> Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MobileHeader;
