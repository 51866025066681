import About from "../home/about";
import WhatWeDo from "../home/whatWeDo";

const Company = () => {
  return (
    <div style={{ marginTop: "6rem" }}>
      <About />
      <WhatWeDo />
    </div>
  );
};

export default Company;
