import Hero from "../../assets/img/hero-bg.jpg";

const Benefit = () => {
  const expertise = [
    "You will get totally transparent & satisfied work",
    "Sourcing multiple products from different states",
    "Personal watch on shipment since day 1 to manufacturing",
    "Negotiate with shipment line, logistic and transportation behalf of you",
    "We ensure about the quality of products & services",
    "We will be negotiate with banks behalf of you",
    "You will get on time delivery of your consignmen",
  ];

  return (
    <section
      id="services"
      className="services section"
      style={{
        backgroundImage: `url(${Hero})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      }}
    >
      <div className="container section-title" data-aos="fade-up">
        <h2 style={{ color: "#000" }}>Benefit</h2>
        <p style={{ color: "#000" }}>What is your benefit </p>
      </div>

      <div className="container">
        <div className="row gy-4">
          {expertise?.map((item) => (
            <div
              key={item}
              className="col-sm-6 col-md-4 col-lg-4 col-md-4"
              data-aos="fade-up"
              data-aos-delay={400}
            >
              <div className="service-item position-relative">
                <p
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  {" "}
                  {item}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Benefit;
