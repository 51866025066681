import { useEffect, useState } from "react";
import MobileHeader from "./mobileHeader";
import DesktopHeader from "./desktopHeader";

const Header = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <>{width > 1200 ? <DesktopHeader /> : <MobileHeader />}</>;
};

export default Header;
