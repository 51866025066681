import FSSAI from "../../assets/img/certificate/fssai.png";
import APEDA from "../../assets/img/certificate/apeda.png";
import FDA from "../../assets/img/certificate/fda.png";
import FICCI from "../../assets/img/certificate/ficci.jpg";
import FIEO from "../../assets/img/certificate/fieo.jpg";
import GMP from "../../assets/img/certificate/gmp.png";
import GST from "../../assets/img/certificate/gst.jpg";
import HALAL from "../../assets/img/certificate/halal.jpg";
import SUI from "../../assets/img/certificate/sui.png";
import MACCIA from "../../assets/img/certificate/maccia.png";
import MSME from "../../assets/img/certificate/msme.png";
import SPICE from "../../assets/img/certificate/spice.jpg";
import DGFD from "../../assets/img/certificate/dgft.png";

const certificates = [
  FSSAI,
  APEDA,
  FDA,
  FICCI,
  FIEO,
  GMP,
  GST,
  HALAL,
  SUI,
  MACCIA,
  MSME,
  SPICE,
  DGFD,
];
const Certification = () => {
  return (
    <div className="row section-title" style={{ margin: "10rem 3rem 5rem" }}>
      <p style={{ marginBottom: "1rem" }}> We are certified </p>
      {certificates?.map((image) => (
        <div
          key={image}
          className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
          style={{ marginBottom: "2rem" }}
        >
          <div className="card">
            <div className="card-body">
              <img
                src={image}
                alt={image}
                style={{
                  width: "100%",
                  height: "auto",
                  scale: "0.7",
                  aspectRatio: "3/2",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Certification;
