import FSSAI from "../../assets/img/certificate/fssai.png";
import APEDA from "../../assets/img/certificate/apeda.png";
import FDA from "../../assets/img/certificate/fda.png";
import FICCI from "../../assets/img/certificate/ficci.jpg";
import FIEO from "../../assets/img/certificate/fieo.jpg";
import GMP from "../../assets/img/certificate/gmp.png";
import GST from "../../assets/img/certificate/gst.jpg";
import HALAL from "../../assets/img/certificate/halal.jpg";
import SUI from "../../assets/img/certificate/sui.png";
import MACCIA from "../../assets/img/certificate/maccia.png";
import MSME from "../../assets/img/certificate/msme.png";
import SPICE from "../../assets/img/certificate/spice.jpg";
import DGFD from "../../assets/img/certificate/dgft.png";
import Carousel from "react-elastic-carousel";
import { useRef } from "react";

const certificates = [
  FSSAI,
  APEDA,
  FDA,
  FICCI,
  FIEO,
  GMP,
  GST,
  HALAL,
  SUI,
  MACCIA,
  MSME,
  SPICE,
  DGFD,
];

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const Certificate = () => {
  const carousel = useRef();
  return (
    <section
      id="services"
      className="services section"
      style={{ backgroundColor: "#fff" }}
    >
      <div className="container section-title" data-aos="fade-up">
        <h2 style={{ color: "#000" }}>Certification</h2>
        <p style={{ color: "#000" }}>Membership & Certification</p>
      </div>

      <div className="container">
        <Carousel
          ref={carousel}
          breakPoints={breakPoints}
          enableAutoPlay={true}
          onChange={(_currentItem, pageIndex) => {
            if (pageIndex === 6)
              setTimeout(() => carousel?.current?.goTo(0), 1000);
          }}
        >
          {certificates.map((certificate) => (
            <img
              key={certificate}
              src={certificate}
              alt={certificate}
              style={{
                width: "100%",
                height: "auto",
                scale: "0.7",
                mixBlendMode: "multiply",
                aspectRatio: "3/2",
                objectFit: "contain",
              }}
            />
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default Certificate;
