import Image1 from "../../assets/img/about.jpg";

const About = () => {
  const aboutItems = [
    "Welcome to One Infinity International. Thank you for your interest in our Company.",
    "One Infinity International able to create its own goodwill and a name for itself. We are primarily exporters of all Wooden Handicraft products, wooden items, glass items, handmade products, photo frames, Handmade Clothes Products, Handmade Decorative Products, all types of wooden temple. The products are of elegance, style and superior quality. We have a well-established global clientele.",
    "The company understands the importance of Quality and Timely Delivery of merchandise in this competitive and challenging International Business scenario.",
  ];

  return (
    <section id="about" className="about section">
      <div
        className="container section-title"
        data-aos="fade-up"
        style={{ paddingBottom: "2rem" }}
      >
        <h2>About</h2>
        <p>About us</p>
      </div>
      <div className="container" data-aos="fade-up" data-aos-delay={100}>
        <div className="row gy-4">
          <div
            className="col-lg-6 order-1 order-lg-2"
            style={{
              backgroundImage: `url(${Image1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          <div className="col-lg-6 order-2 order-lg-1 content">
            <ul>
              {aboutItems?.map((about) => (
                <li key={about}>
                  <i className="bi bi-check2-all" /> <span>{about}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
