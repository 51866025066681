import { useParams } from "react-router-dom";
import packaging1 from "../../assets/img/products/packaging products/packaging1.jpg";
import packaging2 from "../../assets/img/products/packaging products/packaging2.jpg";
import packaging3 from "../../assets/img/products/packaging products/packaging3.jpg";
import packaging4 from "../../assets/img/products/packaging products/packaging4.jpg";
import packaging5 from "../../assets/img/products/packaging products/packaging5.jpg";
import packaging6 from "../../assets/img/products/packaging products/packaging6.jpg";
import packaging7 from "../../assets/img/products/packaging products/packaging7.jpg";
import packaging8 from "../../assets/img/products/packaging products/packaging8.jpg";
import packaging9 from "../../assets/img/products/packaging products/packaging9.jpg";
import packaging10 from "../../assets/img/products/packaging products/packaging10.jpg";
import packaging11 from "../../assets/img/products/packaging products/packaging11.jpg";
import leather1 from "../../assets/img/products/leather products/leather1.jpg";
import leather2 from "../../assets/img/products/leather products/leather2.jpg";
import leather3 from "../../assets/img/products/leather products/leather3.jpg";
import leather4 from "../../assets/img/products/leather products/leather4.jpg";
import leather5 from "../../assets/img/products/leather products/leather5.jpg";
import leather6 from "../../assets/img/products/leather products/leather6.jpg";
import leather7 from "../../assets/img/products/leather products/leather7.jpg";
import leather8 from "../../assets/img/products/leather products/leather8.jpg";
import leather9 from "../../assets/img/products/leather products/leather9.jpg";
import leather10 from "../../assets/img/products/leather products/leather10.jpg";
import insence1 from "../../assets/img/products/insence sticks/insence1.jpg";
import insence2 from "../../assets/img/products/insence sticks/insence2.jpg";
import insence3 from "../../assets/img/products/insence sticks/insence3.jpg";
import insence4 from "../../assets/img/products/insence sticks/insence4.jpg";
import jewellery1 from "../../assets/img/products/imitation jewellery/jewellery1.jpg";
import jewellery2 from "../../assets/img/products/imitation jewellery/jewellery2.jpg";
import jewellery3 from "../../assets/img/products/imitation jewellery/jewellery3.jpg";
import jewellery4 from "../../assets/img/products/imitation jewellery/jewellery4.jpg";
import jewellery5 from "../../assets/img/products/imitation jewellery/jewellery5.jpg";
import jewellery6 from "../../assets/img/products/imitation jewellery/jewellery6.jpg";
import jewellery7 from "../../assets/img/products/imitation jewellery/jewellery7.jpg";
import furniture1 from "../../assets/img/products/furniture/furniture1.jpg";
import furniture2 from "../../assets/img/products/furniture/furniture2.jpg";
import furniture3 from "../../assets/img/products/furniture/furniture3.jpg";
import furniture4 from "../../assets/img/products/furniture/furniture4.jpg";
import furniture5 from "../../assets/img/products/furniture/furniture5.jpg";
import furniture6 from "../../assets/img/products/furniture/furniture6.jpg";
import furniture7 from "../../assets/img/products/furniture/furniture7.jpg";
import furniture8 from "../../assets/img/products/furniture/furniture8.jpg";
import furniture9 from "../../assets/img/products/furniture/furniture9.jpg";
import furniture11 from "../../assets/img/products/furniture/furniture11.jpg";
import furniture12 from "../../assets/img/products/furniture/furniture12.jpg";
import furniture13 from "../../assets/img/products/furniture/furniture13.jpg";
import furniture14 from "../../assets/img/products/furniture/furniture14.jpg";
import furniture15 from "../../assets/img/products/furniture/furniture15.jpg";
import furniture16 from "../../assets/img/products/furniture/furniture16.jpg";
import furniture17 from "../../assets/img/products/furniture/furniture17.jpg";
import "./product.css";

const data = [
  {
    id: 5,
    url: "furniture",
    heading: "Furniture",
    description: [
      "If you’re facing an empty house or a big remodel, you’ve got big choices to make.",
      "Before you decide how to fill your home, learn about the options you have—the types of furniture and the names of furniture pieces—to help you make the best decisions to decorate your rooms.",
      "Furniture is also used to hold objects at a convenient height for work (as horizontal surfaces above the ground, such as tables and desks), or to store things (e.g., cupboards and shelves).",
      "Furniture can be a product of design and is considered a form of decorative art. Wood is the most environmentally friendly raw material. It’s renewable.",
    ],
    cover: furniture1,
    images: [
      furniture2,
      furniture3,
      furniture4,
      furniture5,
      furniture6,
      furniture7,
      furniture8,
      furniture9,
      furniture11,
      furniture12,
      furniture13,
      furniture14,
      furniture15,
      furniture16,
      furniture17,
    ],
  },
  {
    id: 6,
    url: "imitation-jewellery",
    heading: "Imitation Jewellery",
    description: [
      "Artificial Jewellery, also known as costume jewellery, imitation jewellery or fashion jewellery is loved by one and all for its beauty and pocket friendly prices. This discrete fashion accessory is manufactured as a look-alike to real jewellery to complement the costumes and speak a style statement.",
      "Lately, various designers added their charm to imitation jewellery and gave it the designer, modern day touch. Gold plated, rhodium plated, beads, etc. are some of the versions of artificial jewellery.",
      "We are here to export artificial jewellery for male and female categories in very affordable price as per our customer’s needs and requirements.",
    ],
    cover: jewellery1,
    images: [
      jewellery2,
      jewellery3,
      jewellery4,
      jewellery5,
      jewellery6,
      jewellery7,
    ],
  },
  {
    id: 7,
    url: "insence-sticks",
    heading: "Insence Sticks",
    description: [
      "Incense sticks are slender rods made from a mixture of natural materials, including aromatic plant extracts, resins, oils, and powdered wood. These materials are carefully blended and rolled onto a bamboo stick or similar base. When lit, incense sticks release fragrant smoke, creating a calming, meditative, and often spiritual atmosphere. They are commonly used in various cultural, religious, and personal rituals, from enhancing meditation practices to purifying the air and promoting relaxation.",
      "The scent released by incense can vary widely, with popular options including sandalwood, jasmine, lavender, rose, patchouli, and cedarwood. The burning of incense has been practiced for thousands of years, especially in traditions such as Buddhism, Hinduism, and various other spiritual and wellness practices. Aside from their spiritual significance, incense sticks are also used for their soothing effects, enhancing focus, or simply adding a pleasant fragrance to a space.",
      "Incense sticks are available in a wide range of qualities and prices, from basic options to high-end varieties crafted from premium ingredients. They are often sold in boxes or bundles, and each stick can burn for anywhere from 30 minutes to an hour, depending on its size and the materials used. Incense is also enjoyed for its aesthetic value, with designs and colors that reflect its cultural or ritualistic origins.",
    ],
    cover: insence1,
    images: [insence2, insence3, insence4],
  },
  {
    id: 8,
    url: "leather-products",
    heading: "Leather Products",
    description: [
      "One Infinity International is well known for the quality of leather and its a one of the highly leather produce company of India.",
      "Leather products are highly coveted for their classy look and feel. Be its shoes, bags or jackets, pure leather products can always be set apart from the synthetics and the faux leather.",
      "Leather is a strong, flexible and durable material obtained from the tanning, or chemical treatment, of animal skins and hides to prevent decay.",
      "Leather can be used to make a variety of items, including clothing, footwear, handbags, furniture, tools and sports equipment, and lasts for decades.",
    ],
    cover: leather1,
    images: [
      leather2,
      leather3,
      leather4,
      leather5,
      leather6,
      leather7,
      leather8,
      leather9,
      leather10,
    ],
  },
  {
    id: 9,
    url: "packaging-products",
    heading: "Packaging Products",
    description: [
      "One Infinity International packaging Products are known for their quality, durability and the precision they come with. As a quality conscious organization, we ensure quality meets excellence in our output.",
      "Based on our state-of-the-art designing and manufacturing facilities, we provide a huge assortment of qualitative and exclusive range of packaging material that is catering to the requirements of diverse industries across the globe.",
      "Our flexible and versatile packaging material is reckoned for their outstanding features like rigid packaging, brilliant designs and prints, excellent clarity and impact strength.",
      "Our packaging products are Cotton Bag, Eco friendly bag, Jute bag, tote bag, paper bag, plastic bag, corrugated box, paper container, Mono carton and many more products.",
    ],
    cover: packaging1,
    images: [
      packaging2,
      packaging3,
      packaging4,
      packaging5,
      packaging6,
      packaging7,
      packaging8,
      packaging9,
      packaging10,
      packaging11,
    ],
  },
];

const Products = () => {
  const params = useParams();
  const filtered = data?.find((page) => page.url === params?.param1);

  return (
    <section id="about" className="about section">
      <div
        className="container section-title"
        data-aos="fade-up"
        style={{ marginTop: "6rem", paddingBottom: "2rem" }}
      >
        <h2>About</h2>
        <p>About {filtered?.heading}</p>
      </div>
      <div className="container" data-aos="fade-up" data-aos-delay={100}>
        <div className="row gy-4">
          <div
            className="col-lg-6 order-1 order-lg-2"
            style={{
              backgroundImage: `url(${filtered?.cover})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          <div className="col-lg-6 order-2 order-lg-1 content">
            <ul>
              {filtered?.description?.map((descriptionItem) => (
                <li key={descriptionItem}>
                  <i className="bi bi-check2-all" />{" "}
                  <span>{descriptionItem}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay={100}>
        <hr style={{ marginTop: "4rem" }} />
        <h1 className="product-heading"> {filtered?.heading} </h1>
        <div className="row gy-4">
          {filtered?.images?.map((img) => (
            <div className="col-lg-3 child-img-col" key={img}>
              <div className="child-img-wrapper">
                <img src={img} alt={img} className="child-img" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Products;
